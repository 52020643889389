import { Link as NavLink } from 'react-router-dom'
import {createComponentType} from "../../helpers/createComponentType";

export const Link = createComponentType({ type: 'Link', tagname: NavLink, attrs: {
    target: props => props.anchor ? '_self' : '_blank',
    to: props => ({ hash: props.to || '#'}),
    onClick: props => (e) => {
      props.prevent && e.preventDefault()
      props.handler && props.handler()
    }
  }, propsToExclude: ['anchor']})