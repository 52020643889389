import React from 'react';
import Main from './components/Main';
import { Provider } from 'react-redux';
import { Routes, Route, BrowserRouter as Router} from 'react-router-dom';


function App({store}) {
  return (
    <Provider store={ store }>
        <Router>
            <Routes>
                <Route path='/' element={<Main/>} />
            </Routes>
        </Router>
    </Provider>
  );
}

export default App;
