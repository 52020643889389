import React from 'react'
import styled, { css } from 'styled-components'
import {createComponentType} from "../../helpers/createComponentType";

const StyledTitle = styled.h2`
    padding: ${props => props.padding || "0"};
    margin: ${props => props.margin || "0"};
    text-align: ${props => props.center ? "center" : "left"};
`

export const Title = createComponentType({ type: 'Title', tagname: StyledTitle})