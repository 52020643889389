import React from 'react'
import styled from 'styled-components'
import {createComponentType} from "../../helpers/createComponentType";

const WrappedImage = ({src, shape}) => {
  return <StyledImage src={src} shape={shape}/>
}

const StyledImage = styled.img`
    width: 100%;
    border-radius: ${props => props.shape === 'circle' ? '50%' : '0'}
`

export const Image = createComponentType({ type: 'Image', tagname: WrappedImage})