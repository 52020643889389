import { css } from 'styled-components'
import { MOBILE_EDGE, DESKTOP_NARROW_EDGE } from './constants'

export const desktop = (...args) => css`
  @media (min-width: ${MOBILE_EDGE + 1}px) {
    ${css(...args)}
  }
`
export const tablet = (...args) => css`
  @media (min-width: ${MOBILE_EDGE + 1}px) and (max-width: ${DESKTOP_NARROW_EDGE}px) {
    ${css(...args)}
  }
`

export const mobile = (...args) => css`
  @media (max-width: ${MOBILE_EDGE}px) {
    ${css(...args)}
  }
`
