import React from 'react'
import App from './App'
import { mergeThemeStyles } from './helpers/helpers'
import defaultTheme from './mocks/DefaultTheme'
import clientTheme from './mocks/ClientTheme'
import { configureStore } from '@reduxjs/toolkit';
import themeStyles from './reducers/themeStyles'

// const history = createHistory()


const store = configureStore({
    preloadedState: {
        themeStyles: mergeThemeStyles(defaultTheme, clientTheme),
    },
    reducer: {
        themeStyles
    }
  });



export default function ConfiguredApp() {
    return (
        <App store={ store } />
    )
}