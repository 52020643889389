import React from 'react'
import styled from 'styled-components'
import {createComponentType} from "../../helpers/createComponentType";

const StyledContainer = styled.div`
    display: flex;
    justify-content: ${props => props.justifyContent || "left"};
    flex-direction: ${props => props.flexDirection || "row"};
`

export const Container = createComponentType({ type: 'Container', tagname: StyledContainer})