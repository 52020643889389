import bannerPhoto from '../assets/images/liberty.jpg'
import logo from '../assets/images/logo.svg';

const primaryColor = '#219ed9';
const secondaryColor = '#1a4163';
const clientTheme = {
  Themes: {
    colors: {
      primary: primaryColor
    },
    logo: logo
  },
  Section: {
    'padding': "10px 0px",
    '& p': {
      'font-size': "1.25em"
    },
    '& h3': {
      'font-size': "1.5em"
    }
  },
  Header: {
    Logo: {
      'border': '1px solid #000',
      'width': '66px'
    },
    Section: {
      Menu: {
        'border-bottom': '1px solid #e8e8e8',
        MenuItem: {
          Link: {
            '&:hover': {
              'color': 'red'
            }
          }
        }
      }
    }
  },
  Footer: {
    Logo: {
      'width': '66px',
      'border': '1px solid #fff'
    }
  },
  IDThemes: {
    HeroSection: {
      'padding': '0px',
      '& > div': {
        'max-width': 'none'
      }
    },
    HeroBanner: {
      'background-image': `url(${bannerPhoto})`
    },
    home: {
      'padding': '20px 0',
      'text-align': 'center'
    },
    "feedback-title": {
      "background-color": primaryColor,
      "color": "#fff"
    },
    feedback: {
      'min-height': '150px',
      '& > div': {
        "border": `1px solid ${primaryColor}`
      }
    },
    pricing: {
      'text-align': 'center'
    },
    'about-sub-title': {
      '& h2': {
            'font-size': '32px',
        'text-align': 'center'
      }
    },
    'about-title': {

      'text-align': 'left',
      'color': '#fff'
    },
    'about-columns': {
      padding: "10px"
    },
    about: {
      '& h2,h3': {
        color: '#fff'
      },
      'background-color': primaryColor,
      color: "#fff"
    },
    'resources': {
      '& h2,h3': {
        color: '#fff'
      },
      'background-color': secondaryColor,
      'color': '#fff'
    },
    'banner-title': {
      'background': 'rgba(0,0,0,0.3)',
      color: '#fff',
      'font-size': '2em',
      'font-weight': 'bold'
    },
    'contact': {
      'text-align': 'left'
    },
    'contact-us-info': {
      '*': {
        'font-size': '20px'
      }
    }
  }
}

export default clientTheme