import { ThemeConsumer } from 'styled-components';
import biopic from '../assets/images/david-calhoun-sml.jpg'


const landingPage = {
  sections: [
    {
      component: 'Header',
      id: 'NewSectionHeaderID',
      props: {
        children: [
          {
            component: 'Logo',
            props: {
              text: "Immigrant Connection Blue Ridge"
            }
          },
          {
            component: 'Menu',
            props: {
              items: [
                {
                  label: (
                    <a href="#home" rel="noopener noreferrer">
                      Home
                    </a>
                  ),
                  key: 'home'
                },
                {
                  label: (
                    <a href="#about" rel="noopener noreferrer">
                      About Us
                    </a>
                  ),
                  key: 'about'
                },
                {
                  label: (
                    <a href="#legal-clinic" rel="noopener noreferrer">
                      Legal Clinic
                    </a>
                  ),
                  key: 'legal-clinic'
                },
                {
                  label: (
                    <a href="#resources" rel="noopener noreferrer">
                      Resources
                    </a>
                  ),
                  key: 'resources'
                },
                {
                  label: (
                    <a href="#contact-us" rel="noopener noreferrer">
                      Contact Us
                    </a>
                  ),
                  key: 'contact-us'
                }
              ]
            }
          }
        ]
      }
    },
    {
      id: 'HeroSection',
      component: 'Section',
      props: {
        flexDirection: 'column',
      children:[{
        id: 'HeroBanner',
        component: 'Banner',
        props: {
          children: [{
            id: 'banner-title',
            component: 'Title',
            props: {
              as: 'h1',
              children: [
                {
                  component: 'RichText',
                  props: {
                      html: 'Get Help today with your most pressing immigration legal issues!',
                      wrapperElement: 'span'
                  }
                }
              ]
            }
          }]
        }
      }
    ]
    }
    },
    {
      id: 'about-sub-title',
      title: 'SubBanner',
      component: 'Section',
      props: {
        component: 'Container',
          justifyContent: 'center',
          children: [
            {
             component: 'Title',
             props: {
                margin: '10px 0', children: ['Is your representative accredited? ', {component: 'Link', props: {
                to: 'https://www.justice.gov/eoir/recognition-accreditation-roster-reports',
                children: ['See Authorized Representatives']
                }}] 
              } 
            }
          ]
      }
    },
    {
      id : 'about',
      title: 'About Us',
      component: 'Section',
      props: {
        key: 'about',
        children: [
          {
            id: 'about-title',
            component: 'Title',
            props: {
              center: true,
              children: ['About Us/Sobre Nosotros']
            }
          },
          {
            id: 'about-title-sub',
            component: 'Title',
            props: {
              center: true,
              as: 'h3',
              children: ['We exist to open doors and provide a path for those who want to become citizens of the United States of America. We exist as the first point of contact for any immigration issues to get the right help in the most cost effective way, the first time.']
            }
          },
        {
          component: 'Columns',
          props: {
            width: '50% 50%',
            children: [
              {
                component: 'P',
                props: {
                  children: ['We are an approved and certified site that offers assistance to low-income individuals eligible for an immigration benefit. David Calhoun is a certified legal immigration representative by the U.S. Department of Justice. With his training and access to legal resources you have a knowledgable guide who can help you navigate the complex immigration process without incurring heavy legal fees.']
                }
              },
              {
                component: 'P',
                props: {
                  children: ['']
                }
              }
            ]
          }
        },
        {
          id: 'about-biopic',
          component: 'Columns',
          props: {
            adaptive: true,
            verticalAlign: 'middle',
            width: '23% 4%',
            children: [{
              component: 'Image',
              props: {
                src: biopic,
                shape: 'circle'
              }
            },{
              component: ''
            }, {
              component: 'RichText',
              props: {
                html: '<p style="text-align:left;">David Calhoun is a certified legal immigration representative by the U.S. Department of Justice. With his training and access to legal resources you have a knowledgable guide who can help you navigate the complex immigration process without incurring heavy legal fees.<br/>David Calhoun es un representante autorizado por EEUU Departamente de Justicia para hacer los asuntos migratorios. El puede ofrecer ahora con su entrenamiento y aceso de los recursos legales ahora, una guía experta para ayudarle navigar el proceso de inmigración complejo sin acumulación del honorarios altos.</p>'
              }
            }]
          }
        }
      ],
      }
    },
    {
      id : 'legal-clinic',
      title: 'Legal Clinic',
      component: 'Section',
      props: {
        key: 'legal-clinic',
        children: [
          {
            id: 'legal-clinic-title',
            component: 'Title',
            props: {
              children: ['Legal Clinic']
            }
          },
        {
          component: 'Columns',
          props: {
            width: '50% 50%',
            children: [
              {
                component: 'P',
                props: {
                  children: [
                    `Immigrant Connection-Blue Ridge provides low-cost immigration legal services to the Roanoke community and surrounding region. We are located at 3706 Peters Creek Rd, Roanoke VA 24019.
                    If you or someone you know is in need of affordable, high quality immigration legal services, please contact us for an appointment! Call us on any weekday between 6 am and 7 pm at 540 581 2963 or leave us a voicemail if we are unable to answer and we will contact you as soon as possible.
                    
                   You may also schedule an appointment via email at 'immigrantconnectionblueridge@gmail.com'.`
                  ]
                }
              },
              {
                component: 'P',
                props: {
                  children: ['']
                }
              }
            ]
          }
        }
      ],
      }
    },
    {
      id : 'resources',
      title: 'Resources',
      component: 'Section',
      props: {
        key: 'resources',
        children: [
          {
            id: 'resources-title',
            component: 'Title',
            props: {
              children: ['Resources']
            }
          },

        {
          component: 'Columns',
          props: {
            width: '33.3% 33.3% 33.3%',
            children: [
              {
                component: 'Container',
                props: {
                  flexDirection: 'column',
                  children: [
                    {
                      component: 'Title',
                      props: {
                        as: 'h3',
                        children: ['Areas of immigration legal assistance:']
                      }
                    },
                    {
                      component: 'List',
                      props: {
                        children: [
                          "Adjustment of Status",
                          "Consular Processing",
                          "Deferred Action for Childhood Arrivals (DACA)",
                          "Employment authorization",
                          "Family-based petitions",
                          "NACARA",
                          "Naturalization/Citizenship",
                          "Temporary Protected Status (TPS)",
                          "U visas",
                          "Violence Against Women Act (VAWA) Petitions"
                        ]
                      }
                    }
                  ]
                }
              },
              {
                component: 'Container',
                props: {
                  flexDirection: 'column',
                  children: [
                    {
                      component: 'Title',
                      props: {
                        as: 'h3',
                        children: ['Types of immigration legal services provided:']
                      }
                    },
                    {
                      component: 'List',
                      props: {
                        children: [
                          "Form Completion Assistance",
                          "USCIS Filing Assistance",
                          "Document Translation",
                        ]
                      }
                    }
                  ]
                }
              },
              {
                component: 'Container',
                props: {
                  flexDirection: 'column',
                  children: [
                    {
                      component: 'Title',
                      props: {
                        as: 'h3',
                        children: ['Interpretation services:']
                      }
                    },
                    {
                      component: 'List',
                      props: {
                        children: [
                          "English/Spanish",
                          "Spanish/English"
                        ]
                      }
                    }
                  ]
                }
              }                            
            ]
          }
        }
      ],
      }
    },
    {
      id : 'contact-us',
      component: 'Section',
      props: {
        key: 'contact',
        children: [
          {
            id: 'contact-title',
            component: 'Title',
            props: {
              children: ['Contact Us']
            }
          },
          {
            id: 'contact-columns',
            component: 'Columns',
            props: {
              adaptive: true,
              width: '50% 5% 45%',
              children: [
              {
                component: 'Container',
                id: 'contact-us-info',
                props: {
                  flexDirection: 'column',
                  children: [

                    {
                      component: 'P',
                      props: {
                        children: [
                          {
                            component: 'Strong',
                            props: {
                              children: ['Phone: ']
                            }
                          },{
                            component: 'Link',
                            props: {
                              to: 'tel:5405812963',
                              children: ['540-581-2963']
                            }
                          }
                        ]
                      }
                    },
                    {
                      component: 'P',
                      props: {
                        children: [
                          {
                            component: 'Strong',
                            props: {
                              children: ['Email: ']
                            }
                          },{
                            component: 'Link',
                            props: {
                              to: 'mailto:immigrantconnectionblueridge@gmail.com',
                              children: ['immigrantconnectionblueridge@gmail.com']
                            }
                          }
                        ]
                      }
                    },
                    {
                      component: 'P',
                      props: {
                        children: [
                          {
                            component: 'Strong',
                            props: {
                              children: ['Address: ']
                            }
                          },{
                            component: 'Link',
                            props: {
                              to: 'https://www.google.com/maps/place/3706+Peters+Creek+Rd,+Roanoke,+VA+24019/@37.3247459,-79.9944162,17z/data=!3m1!4b1!4m5!3m4!1s0x884d0ec68cab118b:0xd94fd135c04a8363!8m2!3d37.3247459!4d-79.9944162',
                              children: ['3706 Peters Creek Rd, Roanoke, VA 24019']
                            }
                          }
                        ]
                      }
                    }
                  ]
                }
              }]
            }
          }
        ]
      },
      title : 'Contact'
    },

    {
      component: 'Footer',
      props: {
        children: [
          {
            component: 'Section',
            props: {
              children: [
                {
                  component: 'Logo'
                },
                {
                  component: 'P',
                  props: {
                    children: ['© Copyright Immigrant Connection Blue Ridge. All Rights Reserved.']
                  }
                }
              ]
            }
          }
        ]
      }
    },

    {
      component: 'Footer'
    }
  ]};
export default landingPage;