import React from "react"
import { compose } from 'redux'
import { connect } from 'react-redux'
import { ThemeProvider } from 'styled-components'
import landingPage from "../mocks/landingPage"
import {createE} from "../helpers/helpers"
import getThemeStyles from '../selectors/getThemeStyles'

const Main = ({ colorTheme })  =>
  <ThemeProvider theme={ colorTheme }>
    <React.Fragment>
      {!!landingPage && landingPage.sections && landingPage.sections.map(e => createE(e))}
    </React.Fragment>
  </ThemeProvider>

const mapStateToProps = state => ({
  colorTheme: getThemeStyles(state).Themes
})

export default compose(
  connect(mapStateToProps)
)(Main)