import React from 'react'

export default ({ type }) => WrappedComponent => {
  const Wrapper = ({
    scopes = [],
    type: oldType,
    ...props
  }) => {
    const newScope = [...scopes]

    if (oldType) {
      newScope.push(oldType)
    }
    return <WrappedComponent { ...props } scopes={ newScope } type={ type } />
  }
  type && (Wrapper.type = type) // eslint-disable-line

  return Wrapper
}