import React from 'react'
import styled from 'styled-components'
import {createComponentType} from "../../helpers/createComponentType";

const RichTextWrapper = ({ html, wrapperElement='div' }) =>
  wrapperElement === 'div' ? <StyledDiv  dangerouslySetInnerHTML={ {__html: html} } />: <StyledSpan  dangerouslySetInnerHTML={ {__html: html} } />

const StyledDiv = styled.div`
   p {
      font-size: 20px;
   }

`

const StyledSpan = styled.span`
   p {
      font-size: 20px;
   }

`

export const RichText = createComponentType({ type: 'RichText', tagname: RichTextWrapper})