import styled from "styled-components"
import {createComponentType} from "../../helpers/createComponentType";
import { Layout } from 'antd';

const HeaderWrapper = ({children, ...props}) =>
<StyledHeader {...props}>
  <InnerContainer>{children}</InnerContainer>
</StyledHeader>
const StyledHeader = styled(Layout.Header)`
  text-align:right;
  height: 98px;
`
const InnerContainer = styled.div`
  max-width: 1200px;
  margin: auto;
  height: 96px;
  line-height: 96px;
`
export const Header = createComponentType({ type: 'Header', tagname: HeaderWrapper})