import styled from "styled-components"
import {createComponentType} from "../../helpers/createComponentType";

const StyledMenuItem = styled.li.attrs({ className: "ant-menu-overflow-item ant-menu-item ant-menu-item-selected"})`
  position: relative;
  display: inline-block;
  vertical-align: bottom;
  margin: 0;
  padding: 0;
  white-space: nowrap;
  font-variant: tabular-nums;
  list-style: none;
  background: ${props => props.theme.colors.primary};
`

export const MenuItem = createComponentType({ type: 'MenuItem', tagname: StyledMenuItem})