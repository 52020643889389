import { compose } from 'redux';
import { connect } from 'react-redux'
import getThemeStyles from '../../../selectors/getThemeStyles'
import { getThemeStylesByType } from '../../../helpers/helpers'

const mapStateToProps = (state, { id, type, scopes = []}) => ({
  themeStyle: getThemeStylesByType({
    id,
    type,
    scopes,
    themeStyles: getThemeStyles(state)
  })
})

export default compose(
  connect(mapStateToProps)
)