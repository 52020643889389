import React, { useState } from 'react';
import styled from "styled-components"
import {createComponentType} from "../../helpers/createComponentType";
import { Input, Button, Menu as AntMenu } from 'antd';
import useWindowDimensions from "../windowDimensions"
import { AppstoreOutlined, MailOutlined, SettingOutlined, MenuUnfoldOutlined, MenuFoldOutlined } from '@ant-design/icons';


// onClick={this.showDrawer}
const MenuWrapper = ({items}) => {
  const [menuType, setMenuType] = useState('horizontal');
  const [current, setCurrent] = useState('mail');
  const { width, height } = useWindowDimensions();
  const [menuVisibility, setMenuVisibility] = useState(width >= 760);
  const onClick = e => {
    setCurrent(e.key);
  };
  return (
    <React.Fragment>
      { width < 760 ? (<Button className="barsMenu" type="primary"  onClick={() => {setMenuType("inline"); setMenuVisibility(!menuVisibility)}}>
        {!menuVisibility ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
      </Button>) : null
      }
      <StyledMenu onClick={onClick} selectedKeys={[current]} mode={width < 760 ? "inline": "horizontal"} items={items} menuVisibility={menuVisibility} />
    </React.Fragment>
  // <StyledMenu {...props}>
  //   <Button className="barsMenu" type="primary"  onClick={() => setMenuVisibility(!menuVisibility)}>
  //             <span className="barsBtn"></span>
  //   </Button>
  //   <StyledUList className="ant-menu-overflow ant-menu ant-menu-root ant-menu-horizontal ant-menu-light" menuVisibility={menuVisibility}>
  //   { props.children }
  //   </StyledUList>
  // </StyledMenu>
  )
}

const StyledMenu = styled(AntMenu)`
 margin: auto;
 justify-content:flex-end;
 visibility: ${props => props.menuVisibility ? "visible": "hidden"};
 opacity:${props => props.menuVisibility ? 1: 0};
 transition:visibility 0.3s linear,opacity 0.3s linear;
 position: relative;
`

const StyledUList = styled.ul`
visibility: ${props => props.menuVisibility ? "visible": "hidden"};
opacity:${props => props.menuVisibility ? 1: 0};
transition:visibility 0.3s linear,opacity 0.3s linear;
`

export const Menu = createComponentType({ type: 'Menu', tagname: MenuWrapper})