import React from 'react'
import styled from 'styled-components'
import {createComponentType} from "../../helpers/createComponentType";


const StyledList = ({ children, items = [], ...props }) =>
  <StyledUList { ...props }>
    {children.map((child) => <StyledListItem>{ child }</StyledListItem>)}
  </StyledUList>
const StyledUList = styled.ul``
const StyledListItem = styled.li`
  margin-left: 10px;
  font-size: 1.25em
`


export const List = createComponentType({ type: 'List', tagname: StyledList})