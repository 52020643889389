import React, { useState } from 'react';
import { Rate, Button } from 'antd';
import styled from 'styled-components'
import {createComponentType} from "../helpers/createComponentType";
import { Columns, Title } from "./elements";
import { DislikeOutlined, LikeOutlined } from '@ant-design/icons';

const FeedbackButtons = ({setFeedbackType}) =>
<Columns verticalAlign="middle">
    <StyledButton type="primary" shape="round" icon={<LikeOutlined />} size="large" onClick={() => setFeedbackType('like')}>
        Yes
    </StyledButton>
    <StyledButton type="primary" shape="round" icon={<DislikeOutlined />} size="large" onClick={() => setFeedbackType('dislike')}>
        No
    </StyledButton>
</Columns>

const LikeFeedback = ({ reviewLink }) =>
    <FeedbackJumboTron>Awesome! Would you consider giving us a review <a href={reviewLink}>on Google</a>?</FeedbackJumboTron>

const DisLikeFeedback = () =>
    <FeedbackJumboTron>We are sorry to hear about that, please let us know how we can do better with the phone number in the contact section!</FeedbackJumboTron>

const FeedbackWrapper = ({reviewLink, ...props}) => {
    const [feedbackType, setFeedbackType] = useState('unopened');

    return feedbackType === 'unopened' ?
    <StyledRate onChange={(value) => setFeedbackType(value >= 4 ? 'like' : 'dislike')} /> :
    feedbackType === 'like' ? <LikeFeedback reviewLink={reviewLink} /> : <DisLikeFeedback />
}

const StyledRate = styled(Rate)`
    text-align:center;
    font-size: 60px;
`

const FeedbackJumboTron = styled.p`
    text-align: center;
    font-size: 40px;
`

const StyledButton =  styled(Button)`
    margin: auto;
    display: block;
    background: ${props => props.theme.colors.primary};
    border-color: ${props => props.theme.colors.primary};
`


export const Feedback = createComponentType({ type: 'Feedback', tagname: FeedbackWrapper});
